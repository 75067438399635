import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import CrmMasterPrj from '../../crmMasterProject';
import CrmSector from '../../crmSector';
export default {
  name: 'CrmPocketDetails',
  props: {
    parentCrmPocket: Object
  },
  components: {
    DatePicker,
    CrmMasterPrj,
    CrmSector
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      attribute1: null,
      attribute2: null,
      attribute3: null,
      attribute4: null,
      crmMasterPrj: {
        value: null,
        text: null
      },
      crmSector: {
        value: null,
        text: null
      },
      pocketCode: null,
      pocketName: null,
      pocketId: 0,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      version: null,
      approvalStatus: 'Approved',
      sequence: null,
      tab: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode == true) {
          this.addEditCrmPocket();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.pocketId });
        }
      }
    });
    if (this.parentCrmPocket !== null) {
      this.crmMasterPrj = {
        value: this.parentCrmPocket.crm_master_project_id,
        text: this.parentCrmPocket.crm_master_project_name
      };
      this.crmSector = {
        value: this.parentCrmPocket.crm_sector_id,
        text: this.parentCrmPocket.crm_sector_name
      };
      this.pocketCode = this.parentCrmPocket.crm_pocket_code;
      this.pocketId = this.parentCrmPocket.crm_pocket_id;
      this.pocketName = this.parentCrmPocket.crm_pocket_name;
      this.start_date_end_date = [
        new Date(this.parentCrmPocket.start_date),
        new Date(this.parentCrmPocket.end_date)
      ];
      this.version = this.parentCrmPocket.version;
      this.approvalStatus = this.parentCrmPocket.approval_status;
      this.sequence = this.parentCrmPocket.sequence;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmMasterPrj') {
        return (this.childName = 'CRM Master PRJ');
      } else if (tab === 'CrmSector') {
        return (this.childName = 'CRM Sector');
      }
    },
    addEditCrmPocket() {
      const payload = {
        attribute_1: this.attribute1,
        attribute_2: this.attribute2,
        attribute_3: this.attribute3,
        attribute_4: this.attribute4,

        crm_sector_id: this.crmSector.value,
        crm_pocket_code: this.pocketCode,
        crm_pocket_id: this.pocketId,
        crm_pocket_name: this.pocketName,
        sequence: this.sequence,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1])
      };
      this.loader = true;
      this.$store
        .dispatch('crmInventoryProperty/addEditCrmPocket', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateCrmPocketList');
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideChildModal(flag, component) {
      if (component === 'CrmSector' && !this.crmMasterPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.CRMMASTERPRJMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedCrmMasterProj(item) {
      this.showModalComponent = false;
      this.crmMasterPrj = {
        value: item.crm_master_project_id,
        text: item.crm_master_project_name
      };
      this.crmSector = {
        value: null,
        text: null
      };
    },
    selectedCrmSector(item) {
      this.showModalComponent = false;
      this.crmSector = {
        value: item.crm_sector_id,
        text: item.crm_sector_name
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
