import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import CrmMasterProject from '../crmMasterProject';
import CrmSector from '../crmSector';
import CrmPocketDetails from './crmPocketDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'CrmPocket',
  watch: {
    currentPage: function() {
      this.getProjectPocketList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getProjectPocketList();
    }
  },
  components: {
    CrmMasterProject,
    CrmSector,
    CrmPocketDetails
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showValueSetModal: false,
      loader: false,
      editMode: false,
      showAlert: false,
      payload: {},
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      crmMasterPrj: {
        value: null,
        text: null
      },
      crmSector: {
        value: null,
        text: null
      },
      pocketCode: '',
      pocketName: '',
      tab: null,
      parentCrmPocket: null,
      crmPocketData: [],
      crmPocketFields: [
        {
          key: 'crm_master_project_id',
          class: 'd-none'
        },
        {
          key: 'crm_master_project_name',
          class: 'd-none'
        },
        {
          key: 'crm_sector_id',
          class: 'd-none'
        },
        {
          key: 'crm_sector_name',
          class: 'd-none'
        },
        {
          key: 'crm_pocket_id',
          class: 'd-none'
        },
        {
          key: 'crm_pocket_code',
          label: 'Pocket Code'
        },
        {
          key: 'crm_pocket_name',
          label: 'Pocket Name'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'version'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'sequence'
        }
      ]
    };
  },
  validations: {
    crmMasterPrj: {
      text: {
        required
      }
    },
    crmSector: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideModalComponent(true, 'CrmPocketDetails');
          this.parentCrmPocket = null;
        }
        if (actionName === 'download' && !this.showModalComponent) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'crmInventoryProperty/getCrmPocketList',
            'crm-pocket',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    setChildName(tab) {
      //method for child modal name
      if (tab === 'CrmMasterProject') {
        return (this.childName = 'CRM Master Project');
      } else if (tab === 'CrmSector') {
        return (this.childName = 'CRM Sector');
      } else if (tab === 'CrmPocketDetails') {
        return (this.childName = 'CRM Pocket Details');
      }
    },
    getCrmPocketList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          crm_sector_id: this.crmSector.value,
          crm_pocket_code: this.pocketCode,
          crm_pocket_name: this.pocketName
        };
        this.loader = true;
        this.$store
          .dispatch('crmInventoryProperty/getCrmPocketList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status == 200) {
              this.crmPocketData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideModalComponent(flag, component) {
      if (component === 'CrmSector' && !this.crmMasterPrj.text) {
        return alert(appStrings.DEPENDENTVSETMSG.CRMMASTERPRJMSG);
      }
      this.showModalComponent = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    rowSelected(rowData) {
      this.parentCrmPocket = rowData;
      this.$emit('selectedCrmPocket', this.parentCrmPocket);
      this.hideModalComponent(true, 'CrmPocketDetails');
    },
    selectedCrmMasterProj(item) {
      this.showModalComponent = false;
      this.crmMasterPrj = {
        value: item.crm_master_project_id,
        text: item.crm_master_project_name
      };
      this.crmSector = {
        value: null,
        text: null
      };
    },
    selectedCrmSector(item) {
      this.showModalComponent = false;
      this.crmSector = {
        value: item.crm_sector_id,
        text: item.crm_sector_name
      };
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'PRJ_MASTER_PROJECT') {
        this.masterPrj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.masterPrj.value;
      } else {
        this.sector = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    updateCrmPocketList() {
      this.getProjectPocketList();
    },
    resetSearchFilters() {
      this.crmMasterPrj = {
        value: null,
        text: null
      };
      this.crmSector = {
        value: null,
        text: null
      };
      this.pocketCode = null;
      this.pocketName = null;
      this.crmPocketData = [];
      this.totalRows = null;
      this.currentPage = 1;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
